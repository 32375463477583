"use client";

import clsx from "clsx";
import { Loader2, X } from "lucide-react";
import { memo, useState } from "react";
import { ControlProps, GroupBase, components } from "react-select";

import IconArrowDown from "@/components/Icons/IconArrowDown";

type SelectControlProps<T> = ControlProps<
  {
    value: T;
    label: string;
  },
  false,
  GroupBase<{ value: T; label: string }>
>;

const CustomLoadingIndicator = (props: any) => {
  return (
    <div className="flex items-center justify-center px-2">
      <Loader2 className="h-4 w-4 animate-spin text-primary" />
    </div>
  );
};

interface FilterDropdownProps<T> {
  options: Array<{ value: T; label: string }>;
  id: string;
  helperText: string;
  withLabel?: boolean;
  onSelect: (option: any) => void;
  onClear?: () => void;
  value: { value: T; label: string } | null;
  isLoading?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  customNoOptionsMessage?: string;
  customOption?: (option: unknown) => React.ReactNode;
  customClassNames?: {
    control?: (state: SelectControlProps<T>) => string;
    valueContainer?: () => string;
    placeholder?: () => string;
    input?: () => string;
    menu?: () => string;
    menuList?: () => string;
    option?: (state: { isSelected: boolean; isFocused: boolean }) => string;
    singleValue?: () => string;
    indicatorsContainer?: () => string;
    dropdownIndicator?: (state: { selectProps: { menuIsOpen: boolean } }) => string;
    clearIndicator?: () => string;
    indicatorSeparator?: () => string;
    multiValue?: () => string;
    multiValueLabel?: () => string;
    multiValueRemove?: () => string;
    noOptionsMessage?: () => string;
  };
}

// Extracted SearchInput component
const SearchInput = memo(
  ({ value, onChange, placeholder }: { value: string; onChange: (value: string) => void; placeholder: string }) => {
    return (
      <div className="sticky top-0 mb-2 bg-white p-2">
        <input
          type="text"
          className="inter w-full rounded-xl border p-3 text-primary-foreground placeholder:text-secondary-foreground focus:outline-none focus:ring-1 focus:ring-primary"
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    );
  },
);
SearchInput.displayName = "SearchInput";

interface FilterDisplayProps<T> {
  value: { value: T; label: string } | Array<{ value: T; label: string }> | null;
  helperText: string;
  isDisabled: boolean;
  isMulti: boolean;
  isDialogOpen: boolean;
  onClear?: () => void;
  removeOption: (optionToRemove: { value: T; label: string }) => void;
  openDialog: () => void;
}

const FilterDisplay = memo(<T,>(props: FilterDisplayProps<T>) => {
  const { value, helperText, isDisabled, isMulti, isDialogOpen, onClear, removeOption, openDialog } = props;

  return (
    <div
      role="button"
      tabIndex={isDisabled ? -1 : 0}
      aria-haspopup="dialog"
      aria-expanded={isDialogOpen}
      aria-label={`Select ${helperText}`}
      className={clsx(
        "flex items-center justify-between rounded-2xl border border-secondary-light bg-secondary-light p-3 transition-all duration-300 hover:cursor-pointer hover:border-grey-border",
        isDisabled && "pointer-events-none opacity-50",
      )}
      onClick={isDisabled ? undefined : openDialog}
      onKeyDown={(e) => {
        if (!isDisabled && (e.key === "Enter" || e.key === " ")) {
          e.preventDefault();
          openDialog();
        }
      }}
    >
      <div className="flex flex-1 items-center px-3 py-1">
        {value ? (
          isMulti ? (
            <div className="flex flex-wrap gap-2">
              {Array.isArray(value) &&
                value.map((item, index) => (
                  <div key={index} className="flex items-center rounded-lg bg-white p-2">
                    <span className="inter font-semibold text-primary-foreground">{item.label}</span>
                    <button
                      className="ml-2 rounded-full p-1 hover:bg-gray-100"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeOption(item);
                      }}
                      aria-label={`Remove ${item.label}`}
                    >
                      <X className="h-3 w-3 text-gray-500" />
                    </button>
                  </div>
                ))}
            </div>
          ) : (
            <div className="flex items-center">
              <span className="inter font-medium text-[#000000]">{(value as { value: T; label: string }).label}</span>
              {onClear && (
                <button
                  className="ml-2 rounded-full p-1 hover:bg-gray-100"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClear();
                  }}
                  aria-label={`Clear ${(value as { value: T; label: string }).label}`}
                >
                  <X className="h-3 w-3 text-gray-500" />
                </button>
              )}
            </div>
          )
        ) : (
          <span className="inter text-medium text-secondary-foreground">{helperText}</span>
        )}
      </div>
      <div className="flex items-center px-2">
        <IconArrowDown height="16" width="16" fill="hsla(var(--secondary-foreground))"></IconArrowDown>
      </div>
    </div>
  );
});
FilterDisplay.displayName = "FilterDisplay";

interface FullScreenDialogProps<T> {
  isDialogOpen: boolean;
  helperText: string;
  isLoading: boolean;
  isSearchable: boolean;
  isMulti: boolean;
  searchInput: string;
  filteredOptions: Array<{ value: T; label: string }>;
  localValue: { value: T; label: string } | Array<{ value: T; label: string }> | null;
  customNoOptionsMessage?: string;
  handleSearch: (value: string) => void;
  closeDialog: () => void;
  handleConfirm: () => void;
  setLocalValue: (value: { value: T; label: string } | Array<{ value: T; label: string }> | null) => void;
  onClear?: () => void;
  customOption?: (option: unknown) => React.ReactNode;
}

const FullScreenDialog = memo(<T,>(props: FullScreenDialogProps<T>) => {
  const {
    isDialogOpen,
    helperText,
    isLoading,
    isSearchable,
    isMulti,
    searchInput,
    filteredOptions,
    localValue,
    customNoOptionsMessage,
    handleSearch,
    closeDialog,
    handleConfirm,
    setLocalValue,
    onClear,
    customOption,
  } = props;

  if (!isDialogOpen) return null;

  return (
    <div className="fixed inset-0 z-100000 flex flex-col bg-white">
      <div className="flex items-center justify-between border-b p-4">
        <h2 className="font-urbanist text-lg font-semibold">{helperText}</h2>
        <button onClick={closeDialog} className="rounded-full p-2 hover:bg-gray-100">
          <X className="h-6 w-6" />
        </button>
      </div>
      <div className="flex-1 overflow-y-auto">
        {isLoading ? (
          <div className="flex h-full items-center justify-center">
            <CustomLoadingIndicator size={32} />
          </div>
        ) : (
          <div className="py-2">
            {isSearchable && (
              <SearchInput
                value={searchInput}
                onChange={handleSearch}
                placeholder={`Search ${helperText.toLowerCase()}...`}
              />
            )}
            <div role="listbox" aria-multiselectable={isMulti}>
              {filteredOptions.length > 0 ? (
                filteredOptions.map((option) => {
                  const isSelected = isMulti
                    ? Array.isArray(localValue) && localValue.some((item) => item.value === option.value)
                    : !Array.isArray(localValue) && localValue?.value === option.value;

                  return (
                    <div
                      key={String(option.value)}
                      role="option"
                      tabIndex={0}
                      aria-selected={isSelected}
                      className={clsx(
                        "inter cursor-pointer px-3 py-4",
                        isSelected ? "bg-secondary-light" : "hover:bg-gray-50",
                      )}
                      onClick={() => {
                        if (isMulti) {
                          let newValue;
                          if (Array.isArray(localValue)) {
                            if (isSelected) {
                              newValue = localValue.filter((item) => item.value !== option.value);
                            } else {
                              newValue = [...localValue, option];
                            }
                          } else {
                            newValue = [option];
                          }
                          setLocalValue(newValue.length ? newValue : (null as any));
                        } else {
                          setLocalValue(option);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          e.preventDefault();
                          if (isMulti) {
                            let newValue;
                            if (Array.isArray(localValue)) {
                              if (isSelected) {
                                newValue = localValue.filter((item) => item.value !== option.value);
                              } else {
                                newValue = [...localValue, option];
                              }
                            } else {
                              newValue = [option];
                            }
                            setLocalValue(newValue.length ? newValue : null);
                          } else {
                            setLocalValue(option);
                          }
                        }
                      }}
                    >
                      <div className="flex items-center justify-between">
                        {customOption ? (
                          customOption(option)
                        ) : (
                          <span className="text-base font-medium">{option.label}</span>
                        )}
                        {isSelected && (
                          <svg className="h-5 w-5 text-primary" viewBox="0 0 20 20" fill="currentColor">
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="inter py-8 text-center text-secondary-foreground">
                  {customNoOptionsMessage || "No options available"}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="border-t p-4">
        <div className="flex gap-4">
          {onClear && (
            <button
              onClick={() => {
                setLocalValue(null);
              }}
              className="inter flex-1 rounded-xl border bg-primary-light px-4 py-3 text-center font-medium text-primary"
            >
              Clear
            </button>
          )}
          <button
            onClick={handleConfirm}
            className="inter flex-1 rounded-xl bg-primary px-4 py-3 text-center font-medium text-white"
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
});
FullScreenDialog.displayName = "FullScreenDialog";

export default function FilterDropdownFullScreen<T>({
  options,
  id,
  helperText,
  withLabel = false,
  onSelect,
  onClear,
  value,
  isLoading = false,
  isSearchable = true,
  isDisabled = false,
  isMulti = false,
  customNoOptionsMessage,
  customClassNames = {},
  customOption,
}: FilterDropdownProps<T>) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [localValue, setLocalValue] = useState<{ value: T; label: string } | Array<{ value: T; label: string }> | null>(
    value,
  );
  const [searchInput, setSearchInput] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);

  const openDialog = () => {
    setLocalValue(value);
    setSearchInput("");
    setFilteredOptions(options);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const handleConfirm = () => {
    if (localValue === null && onClear) {
      onClear();
    } else if (localValue) {
      onSelect(localValue);
    }
    closeDialog();
  };

  const handleSearch = (value: string) => {
    setSearchInput(value);
    if (value.trim() === "") {
      setFilteredOptions(options);
    } else {
      const filtered = options.filter((option) => option.label.toLowerCase().includes(value.toLowerCase()));
      setFilteredOptions(filtered);
    }
  };

  const removeOption = (optionToRemove: { value: T; label: string }) => {
    if (isMulti && Array.isArray(value)) {
      const newValue = value.filter((item) => item.value !== optionToRemove.value);
      if (newValue.length > 0) {
        onSelect(newValue);
      } else if (onClear) {
        onClear();
      }
    } else if (!isMulti && onClear) {
      onClear();
    }
  };

  return (
    <div className="w-full">
      {withLabel && <label className="inter mb-2 block text-sm font-semibold text-[#000000]">{helperText}</label>}
      <FilterDisplay
        value={value}
        helperText={helperText}
        isDisabled={isDisabled}
        isMulti={isMulti}
        isDialogOpen={isDialogOpen}
        onClear={onClear}
        removeOption={removeOption as any}
        openDialog={openDialog}
      />
      <FullScreenDialog
        isDialogOpen={isDialogOpen}
        helperText={helperText}
        isLoading={isLoading}
        isSearchable={isSearchable}
        isMulti={isMulti}
        searchInput={searchInput}
        filteredOptions={filteredOptions}
        localValue={localValue}
        customNoOptionsMessage={customNoOptionsMessage}
        handleSearch={handleSearch}
        closeDialog={closeDialog}
        handleConfirm={handleConfirm}
        setLocalValue={setLocalValue as any}
        onClear={onClear}
        customOption={customOption}
      />
    </div>
  );
}
