import {
  apiRequest,
  ReturnTypeWithPagination,
  timeAsSeconds,
  VehicleBodyType,
  VehicleBodyTypeGetDTO,
} from "@alba-cars/common-modules";

export const getVehicleBodyTypes = async (filters?: VehicleBodyTypeGetDTO & { relevant?: string }) => {
  const endpoint = filters?.relevant ? `/vehicle/body-types?relevant=${filters.relevant}` : "/vehicle/body-types";
  const response = await apiRequest<ReturnTypeWithPagination<VehicleBodyType[]>>(endpoint, {
    next: {
      revalidate: timeAsSeconds({ hours: 1 }),
      tags: ["vehicle-body-types"],
    },
  });

  return response;
};

export const getVehicleBodyTypesOptions = async (
  filters?: VehicleBodyTypeGetDTO & { relevant?: string },
): Promise<(VehicleBodyType & { label: string; value: string })[]> => {
  const response = await getVehicleBodyTypes(filters);

  const bodyTypes = response.data?.result ?? [];

  return bodyTypes.map((bodyType) => ({
    ...bodyType,
    label: bodyType.name,
    value: bodyType.id,
  }));
};
