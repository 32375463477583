import { FC } from "react";

interface IconArrowDownProps {
  className?: string;
  width?: string;
  height?: string;
  fill?: string;
}

const IconArrowDown: FC<IconArrowDownProps> = ({ className, width, height, fill }) => {
  return (
    <svg width={width ?? "20"} height={height ?? "21"} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M2.41406 7.89164L9.41406 14.9124C9.49739 14.9958 9.58767 15.0549 9.68489 15.0899C9.78211 15.1249 9.88628 15.1421 9.99738 15.1416C10.1085 15.1416 10.2127 15.1244 10.3099 15.0899C10.4071 15.0555 10.4974 14.9963 10.5807 14.9124L17.6016 7.89165C17.796 7.6972 17.8932 7.45415 17.8932 7.16248C17.8932 6.87081 17.7891 6.62081 17.5807 6.41248C17.3724 6.20415 17.1294 6.09998 16.8516 6.09998C16.5738 6.09998 16.3307 6.20415 16.1224 6.41248L9.99738 12.5374L3.87239 6.41248C3.67795 6.21803 3.43822 6.12081 3.15322 6.12081C2.86822 6.12081 2.62183 6.22498 2.41406 6.43331C2.20572 6.64164 2.10156 6.8847 2.10156 7.16248C2.10156 7.44025 2.20572 7.68331 2.41406 7.89164Z"
        fill={fill ?? "#65717F"}
      />
    </svg>
  );
};

export default IconArrowDown;
