"use client";

import { formatNumberWithCommas } from "@alba-cars/common-modules";
import { useEffect, useMemo, useState } from "react";

import FilterDropdown from "./FilterDropdown";

interface RangeFilterProps<T> {
  onFromChange: (value: { value: T; label: string } | null) => void;
  onToChange: (value: { value: T; label: string } | null) => void;
  onFromClear?: () => void;
  onToClear?: () => void;
  onReset?: () => void;
  options: { value: T; label: string }[];
  label: string;
  fromValue?: { value: T; label: string } | null;
  toValue?: { value: T; label: string } | null;
  skipFormatting?: boolean;
}

export default function RangeFilter<T>({
  onFromChange,
  onToChange,
  onFromClear,
  onToClear,
  onReset,
  options,
  label,
  fromValue,
  toValue,
  skipFormatting = false,
}: RangeFilterProps<T>) {
  const [fromValueState, setFromValueState] = useState<{ value: T; label: string } | null>(fromValue ?? null);
  const [toValueState, setToValueState] = useState<{ value: T; label: string } | null>(toValue ?? null);

  useEffect(() => {
    setFromValueState(
      fromValue
        ? {
            value: fromValue.value,
            label:
              typeof fromValue.value === "number" && !skipFormatting
                ? formatNumberWithCommas(fromValue.value.toString())
                : fromValue.label,
          }
        : null,
    );
    setToValueState(
      toValue
        ? {
            value: toValue.value,
            label:
              typeof toValue.value === "number" && !skipFormatting
                ? formatNumberWithCommas(toValue.value.toString())
                : toValue.label,
          }
        : null,
    );
  }, [fromValue, toValue, skipFormatting]);

  // Filter options based on selected values
  const fromOptions = useMemo(() => {
    if (!toValueState) return options;
    return options.filter((option) =>
      typeof option.value === "number" && typeof toValueState.value === "number"
        ? option.value < toValueState.value
        : true,
    );
  }, [options, toValueState]);

  const toOptions = useMemo(() => {
    if (!fromValueState) return options;
    return options.filter((option) =>
      typeof option.value === "number" && typeof fromValueState.value === "number"
        ? option.value > fromValueState.value
        : true,
    );
  }, [options, fromValueState]);

  const handleFromChange = (option: { value: T; label: string } | null) => {
    if (
      option &&
      toValueState &&
      typeof option.value === "number" &&
      typeof toValueState.value === "number" &&
      option.value >= toValueState.value
    ) {
      setToValueState(null);
      onToChange?.(null);
    }
    setFromValueState(option);
    onFromChange?.(option ?? null);
  };

  const handleToChange = (option: { value: T; label: string } | null) => {
    if (
      option &&
      fromValueState &&
      typeof option.value === "number" &&
      typeof fromValueState.value === "number" &&
      option.value <= fromValueState.value
    ) {
      setFromValueState(null);
      onFromChange?.(null);
    }
    setToValueState(option);
    onToChange?.(option ?? null);
  };

  const handleFromClear = () => {
    setFromValueState(null);
    if (onFromClear) onFromClear();
  };

  const handleToClear = () => {
    setToValueState(null);
    if (onToClear) onToClear();
  };

  const handleReset = () => {
    setFromValueState(null);
    setToValueState(null);
    onFromChange?.(null);
    onToChange?.(null);
    if (onReset) onReset();
  };

  return (
    <div className="w-full">
      <div className="mb-2 flex items-center justify-between">
        <span className="inter text-sm font-semibold text-[#000000]">{label}</span>
        <button
          onClick={handleReset}
          className="inter text-sm text-secondary-foreground transition-colors hover:cursor-pointer hover:text-primary"
        >
          Reset
        </button>
      </div>
      <div className="flex items-stretch rounded-2xl bg-secondary-light">
        <FilterDropdown<T>
          options={fromOptions}
          id={`${label.toLowerCase()}-from`}
          helperText="From"
          onSelect={handleFromChange}
          isSearchable={false}
          onClear={handleFromClear}
          value={fromValueState}
          customClassNames={{
            control: () => "!bg-transparent !p-1 !min-h-0",
            valueContainer: () => "flex flex-wrap flex-1 px-3 py-2.5 gap-1",
            menu: () => "mt-1 border border-secondary-light rounded-2xl bg-white shadow-lg absolute w-[200px] z-10",
          }}
        />
        <div className="w-[2px] bg-gray-200" />
        <FilterDropdown<T>
          options={toOptions}
          id={`${label.toLowerCase()}-to`}
          helperText="To"
          isSearchable={false}
          onSelect={handleToChange}
          onClear={handleToClear}
          value={toValueState}
          customClassNames={{
            control: () => "!bg-transparent !p-1 !min-h-0",
            valueContainer: () => "flex flex-wrap flex-1 px-3 py-2.5 gap-1",
            menu: () => "mt-1 border border-secondary-light rounded-2xl bg-white shadow-lg absolute w-[200px] z-10",
          }}
        />
      </div>
    </div>
  );
}
