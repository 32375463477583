import {
  apiRequest,
  ReturnTypeWithPagination,
  API_ENDPOINTS,
  VehicleFeaturedGetDTO,
  VehicleFeature,
  timeAsSeconds,
} from "@alba-cars/common-modules";
import qs from "qs";

export const getVehicleFeatures = async () => {
  const query = {
    options: {
      select: ["id", "name", "slug", "refId"],
      limit: 1000,
    },
  };
  const queryString = qs.stringify(query, {
    skipNulls: true,
    allowEmptyArrays: false,
    allowDots: false,
    arrayFormat: "repeat",
  });

  const endpoint = `/vehicle/features?${queryString}`;
  const response = await apiRequest<ReturnTypeWithPagination<VehicleFeature[]>>(endpoint, {
    next: {
      revalidate: 0,
      tags: ["vehicle-features"],
    },
  });

  return response;
};

export const getVehicleFeaturesOptions = async (
  filters?: VehicleFeaturedGetDTO,
): Promise<(VehicleFeature & { label: string; value: string })[]> => {
  const response = await getVehicleFeatures();

  const features = response.data?.result ?? [];

  return features.map((feature) => ({
    ...feature,
    label: feature.name,
    value: feature.id,
  }));
};
