"use client";

import clsx from "clsx";
import { Loader2 } from "lucide-react";
import Select, { ControlProps, GroupBase, components } from "react-select";

type SelectControlProps<T> = ControlProps<
  {
    value: T;
    label: string;
  },
  false,
  GroupBase<{ value: T; label: string }>
>;

// Custom loading indicator component
const CustomLoadingIndicator = (props: any) => {
  return (
    <div className="flex items-center justify-center px-2">
      <Loader2 className="h-4 w-4 animate-spin text-primary" />
    </div>
  );
};
const CustomNoOptionsMessage = ({ children, ...props }: any) => {
  return (
    <components.NoOptionsMessage {...props}>
      <div className="custom-no-options text-secondary-foreground">{children}</div>
    </components.NoOptionsMessage>
  );
};

interface FilterDropdownProps<T> {
  options: Array<{ value: T; label: string }>; // react-select requires this format
  id: string;
  helperText: string;
  withLabel?: boolean;
  onSelect: (option: any) => void;
  onClear?: () => void;
  value: { value: T; label: string } | null;
  isLoading?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  customNoOptionsMessage?: () => string;
  customClassNames?: {
    control?: (state: SelectControlProps<T>) => string;
    valueContainer?: () => string;
    placeholder?: () => string;
    input?: () => string;
    menu?: () => string;
    menuList?: () => string;
    option?: (state: { isSelected: boolean; isFocused: boolean }) => string;
    singleValue?: () => string;
    indicatorsContainer?: () => string;
    dropdownIndicator?: (state: { selectProps: { menuIsOpen: boolean } }) => string;
    clearIndicator?: () => string;
    indicatorSeparator?: () => string;
    multiValue?: () => string;
    multiValueLabel?: () => string;
    multiValueRemove?: () => string;
    noOptionsMessage?: () => string;
  };
}

export default function FilterDropdown<T>({
  options,
  id,
  helperText,
  withLabel = false,
  onSelect,
  onClear,
  value,
  isLoading = false,
  isSearchable = true,
  isDisabled = false,
  isMulti = false,
  customNoOptionsMessage,
  customClassNames = {},
}: FilterDropdownProps<T>) {
  const defaultClassNames = {
    // Controls the main container of the select component
    control: (state: SelectControlProps<T>) =>
      clsx(
        "flex items-center bg-secondary-light border border-secondary-light rounded-2xl  hover:cursor-pointer hover:border-grey-border p-3 transition-all duration-300",
        state.isFocused ? "border-grey-border ring-1 ring-grey-border" : "",
        // state.isFocused ? "border-primary ring-1 ring-primary" : "outline-gray-300",
        state.isDisabled && "opacity-50",
      ),
    // Container that holds the selected value/placeholder and input
    valueContainer: () => "flex flex-wrap flex-1 px-3 py-1 gap-1",
    // Styles for the placeholder text when no value is selected
    placeholder: () => "inter text-secondary-foreground text-medium",
    // Styles for the input element when typing
    input: () => "text-secondary-foreground font-medium !text-base inter focus:outline-none p-0 m-0",
    // Styles for the dropdown menu container
    // menu: () =>
    //   "mt-1 mb-2 border border-secondary-light rounded-2xl bg-white shadow-lg !fixed lg:!absolute !top-20 !left-0 w-full !z-50 max-h-[80vh] no-scrollbar  lg:max-h-[400px] !overflow-y-auto lg:!top-full",
    // // Styles for the list of options inside the menu
    // menuList: () => "inter font-medium !overflow-y-scroll !max-h-[calc(80vh-16px)] lg:max-h-[384px] no-scrollbar ",
    menu: () => "mt-1 mb-2 border border-secondary-light rounded-2xl bg-white shadow-lg absolute w-full !z-50",
    // Styles for the list of options inside the menu
    menuList: () => "max-h-60 inter font-medium overflow-auto no-scrollbar",
    // Styles for individual options in the dropdown
    option: ({ isSelected, isFocused }: { isSelected: boolean; isFocused: boolean }) =>
      clsx(
        "px-4 py-2 cursor-pointer inter font-medium first:rounded-t-2xl last:rounded-b-2xl",
        isSelected && "bg-secondary-light text-black",
        !isSelected && isFocused && "bg-secondary-light text-black ",
        !isSelected && !isFocused && "text-gray-800 hover:bg-gray-50",
      ),
    // Styles for the selected value text
    singleValue: () => "text-[#000000] inter font-medium",
    // Container for the dropdown indicator and clear button
    indicatorsContainer: () => "flex items-center px-2",
    // Styles for the dropdown indicator (arrow)
    dropdownIndicator: (state: { selectProps: { menuIsOpen: boolean } }) =>
      clsx(
        "!text-gray-400 !hover:text-gray-600",
        "!transition-all !duration-300 !ease-in-out",
        state.selectProps.menuIsOpen ? "!rotate-180" : "!rotate-0",
      ),
    // Styles for the clear button
    clearIndicator: () => "text-primary p-1",
    // Separator between the dropdown indicator and clear button
    indicatorSeparator: () => "hidden",

    loadingMessage: () => "text-primary px-2 py-3",

    // Styles for the multi-value container
    multiValue: () => "bg-white text-white p-2 rounded-lg",

    // Styles for the multi-value label
    multiValueLabel: () => "text-black inter font-semibold",

    // Styles for the multi-value remove button
    multiValueRemove: () => "text-primary ms-2",

    // Styles for the no options message
    noOptionsMessage: () => "text-secondary-foreground p-4",
    customNoOptionsMessage: () => "!text-secondary-foreground",
  };

  const classNames = Object.fromEntries(
    Object.entries(defaultClassNames).map(([key, defaultFn]) => [
      key,
      customClassNames[key as keyof typeof customClassNames]
        ? (state: any) => clsx(defaultFn(state), customClassNames[key as keyof typeof customClassNames]?.(state))
        : defaultFn,
    ]),
  );

  return (
    <div className="w-full">
      {withLabel && <label className="inter mb-2 block text-sm font-semibold text-[#000000]">{helperText}</label>}
      <Select
        unstyled
        instanceId={id}
        placeholder={helperText}
        options={options}
        onChange={(option) => {
          if (option === null && onClear) {
            onClear();
          } else {
            option && onSelect(option);
          }
        }}
        isSearchable={isSearchable}
        classNames={classNames}
        isClearable
        isLoading={isLoading}
        isDisabled={isDisabled}
        isMulti={isMulti}
        value={value}
        components={{
          ...components,
          LoadingIndicator: CustomLoadingIndicator,
          NoOptionsMessage: CustomNoOptionsMessage,
        }}
        noOptionsMessage={() => customNoOptionsMessage?.() || "No options available"}
        closeMenuOnSelect={!isMulti}
      />
    </div>
  );
}
