import {
  apiRequest,
  ReturnTypeWithPagination,
  API_ENDPOINTS,
  VehicleModel,
  VehicleModelGetDTO,
  timeAsSeconds,
} from "@alba-cars/common-modules";
import qs from "qs";

import { PaginatedResponse } from "@/components/common/forms/FormAutoComplete";

export const getVehicleModels = async (filters?: Omit<VehicleModelGetDTO, "validate" | "toPlain">) => {
  const queryString = qs.stringify(
    { ...filters, search: filters?.filter?.search, options: { ...(filters?.options ?? {}), withMake: true } },
    {
      skipNulls: true,
      allowEmptyArrays: false,
      allowDots: false,
      arrayFormat: "brackets",
    },
  );

  const endpoint = `${API_ENDPOINTS.vehicleModel.getAll.toString()}?${queryString}`;

  const response = await apiRequest<ReturnTypeWithPagination<VehicleModel[]>>(endpoint, {
    next: {
      revalidate: timeAsSeconds({ hours: 1 }),
      tags: ["vehicle-models", queryString],
    },
  });

  return response;
};

export const getVehicleModelsOptions = async (
  filters?: Omit<VehicleModelGetDTO, "validate" | "toPlain">,
): Promise<(VehicleModel & { label: string; value: string })[]> => {
  const response = await getVehicleModels(filters);

  const models = response.data?.result ?? [];

  console.log(models, "models");

  return models.map((model) => ({
    ...model,
    label: model.name,
    value: model.id,
  }));
};

export const getVehicleModelsPaginatedOptions = async (
  inputValue: string,
  page?: number | undefined,
  filter?: Record<string, string>,
): Promise<PaginatedResponse<VehicleModel & { label: string; value: string }>> => {
  const dto = new VehicleModelGetDTO();
  dto.filter = {
    search: inputValue,
    ...filter,
    validate(): string[] {
      return [];
    },
  };
  dto.options = {
    page: page || 1,
    limit: 20,
    validate(): string[] {
      return [];
    },
  };
  const response = await getVehicleModels(dto);

  const models = response.data?.result ?? [];
  const total = response.data?.total;
  const currentPage = response.data?.page;

  return {
    data: models.map((model) => ({
      ...model,
      label: model.name,
      value: model.id,
    })),
    meta: {
      currentPage: currentPage || 1,
      totalPages: total || 1,
      hasNextPage: (currentPage || 1) < (currentPage || 1),
    },
  };
};
