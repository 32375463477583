"use client";

import { NumberRange, VehicleFilter, VehicleOptions, VehicleSort } from "@alba-cars/common-modules";
import { usePathname, useRouter } from "next/navigation";
import { createContext, useCallback, useContext, useMemo, useState, useTransition } from "react";

import { FilterArrayField, FilterRangeFields, OmittedVehicleGetDTO } from "../types";
import { baseQuery, MAX_SAFE_INTEGER } from "@/lib/utils";

interface FilterContextType {
  queryOptions: OmittedVehicleGetDTO;
  updateQuery: (queryOptions: Partial<OmittedVehicleGetDTO>) => void;
  resetQuery: () => void;
  initialQuery: OmittedVehicleGetDTO;
  getCurrentFilters: () => VehicleFilter;
  getCurrentSort: () => Record<string, "asc" | "desc" | Record<string, "asc" | "desc">> | undefined;
  updateFilters: (filter: VehicleFilter, shouldScroll?: boolean) => void;
  updateOptions: (options: VehicleOptions, shouldScroll?: boolean) => void;
  encodeFilterToURL: (
    filter: Record<string, any>,
    sort?: Record<string, "asc" | "desc" | Record<string, "asc" | "desc">>,
  ) => URLSearchParams;
  isStateUpdating: boolean;
}

const FilterContext = createContext<FilterContextType | undefined>(undefined);

export const ARRAY_FIELDS: FilterArrayField[] = ["make", "model", "bodyType", "fuelType", "features", "odometer"];
export const RANGE_FIELDS: FilterRangeFields[] = ["mileage", "year", "price", "emi"];

const encodeFilterToURL = (
  filter: Record<string, any>,
  sort?: Record<string, "asc" | "desc" | Record<string, "asc" | "desc">>,
): URLSearchParams => {
  const params = new URLSearchParams();

  Object.entries(filter).forEach(([key, value]) => {
    if (value === undefined || value === null) return;

    // Handle the special case for minPrice and maxPrice
    if (["minPrice", "minprice"].includes(key)) {
      params.set("priceMin", value.toString());
      return;
    }
    if (["maxPrice", "maxprice"].includes(key)) {
      params.set("priceMax", value.toString());
      return;
    }

    if (Array.isArray(value)) {
      value.forEach((v) => {
        if (!!v) {
          params.append(key, v.toString());
        }
      });
    } else if (typeof value === "object" && !("validate" in value)) {
      const range = value as NumberRange;
      if (range.min !== undefined) params.set(`${key}Min`, range.min.toString());
      if (range.max !== undefined) params.set(`${key}Max`, range.max.toString());
    } else if (typeof value === "string" || typeof value === "number") {
      const arrayValue = value.toString().split(",");

      if (arrayValue.length > 1) {
        arrayValue.forEach((v) => {
          if (!!v) {
            params.append(key, v.toString());
          }
        });
      } else {
        params.set(key, value.toString());
      }
    }
  });

  if (sort) {
    Object.entries(sort).forEach(([key, value]) => {
      if (typeof value === "string") {
        // Handle simple sort like {price: 'asc'}
        params.set(`sort[${key}]`, value);
      } else if (typeof value === "object") {
        // Handle nested sort like {finance: {price: 'asc'}}
        Object.entries(value).forEach(([nestedKey, nestedValue]) => {
          params.set(`sort[${key}][${nestedKey}]`, nestedValue);
        });
      }
    });
  }

  return params;
};

const decodeURLToFilter = (
  searchParams: URLSearchParams,
): { filter: VehicleFilter; sort?: Record<string, "asc" | "desc" | Record<string, "asc" | "desc">> } => {
  const filter = new VehicleFilter();
  const sort: Record<string, "asc" | "desc" | Record<string, "asc" | "desc">> = {};

  Array.from(searchParams.entries()).forEach(([key, value]) => {
    if (key.startsWith("sort[")) {
      const matches = key.match(/sort\[(.*?)\](?:\[(.*?)\])?/);
      if (matches) {
        const [, mainKey, nestedKey] = matches;

        if (nestedKey) {
          // Handle nested sort
          if (!sort[mainKey] || typeof sort[mainKey] === "string") {
            sort[mainKey] = {};
          }
          (sort[mainKey] as Record<string, "asc" | "desc">)[nestedKey] = value as "asc" | "desc";
        } else {
          // Handle simple sort
          sort[mainKey] = value as "asc" | "desc";
        }
      }
    }
  });

  const chassisNumber = searchParams.get("chassisNumber");
  if (chassisNumber) {
    filter.chassisNumber = chassisNumber;
  }

  ARRAY_FIELDS.forEach((field) => {
    const values = searchParams.getAll(field);
    if (field === "odometer") {
      return;
    }
    if (values.length) {
      filter[field] = values[0].split(",");
    }
  });

  // Process special price parameters first
  const minPrice = searchParams.get("minPrice") || searchParams.get("minprice");
  const maxPrice = searchParams.get("maxPrice") || searchParams.get("maxprice");

  // Set price range if either parameter is present
  if (minPrice || maxPrice) {
    filter.price = {
      min: minPrice ? Number(minPrice) : undefined,
      max: maxPrice ? Number(maxPrice) : undefined,
    };
  }

  const odometer = searchParams.get("odometer");

  if (odometer) {
    filter.mileage = {
      max: odometer ? Number(odometer) : undefined,
    };
  }

  RANGE_FIELDS.forEach((field) => {
    if (field === "price" && filter.price) {
      return;
    }

    if (field === "mileage" && filter.mileage) {
      return;
    }

    const min = searchParams.get(`${field}Min`);
    const max = searchParams.get(`${field}Max`);
    if (min || max) {
      filter[field] = {
        min: min ? Number(min) : undefined,
        max: max ? Number(max) : undefined,
      };
    }
  });

  return {
    filter,
    ...(Object.keys(sort).length > 0 ? { sort: sort as VehicleSort } : {}),
  };
};

export const FilterProvider: React.FC<{
  searchParams: Record<string, any>;
  children: React.ReactNode;
}> = ({ searchParams, children }) => {
  const router = useRouter();
  const pathname = usePathname();

  // const [isStateUpdating, setIsStateUpdating] = useState(false);
  const [isPending, startTransition] = useTransition();

  const [queryOptions, setQueryOptions] = useState<OmittedVehicleGetDTO>(() => {
    const params = new URLSearchParams(searchParams as any);
    const hasFilterParams = Array.from(params.keys()).some(
      (key) =>
        key.endsWith("Min") ||
        key.endsWith("Max") ||
        key.startsWith("max") ||
        key.startsWith("min") ||
        ARRAY_FIELDS.includes(key as FilterArrayField),
    );

    const { filter: initialFilter, sort: initialSort } = decodeURLToFilter(params);

    if (!hasFilterParams && !initialSort) {
      return {
        filter: new VehicleFilter(),
        options: { validate: () => [] },
      };
    }

    return {
      ...baseQuery,
      filter: Object.keys(initialFilter).length > 0 ? initialFilter : undefined,
      options: {
        ...(baseQuery?.options ?? { validate: () => [] }),
        sort: initialSort,
      },
    };
  });

  const getCurrentFilters = useCallback((): VehicleFilter => {
    return decodeURLToFilter(new URLSearchParams(searchParams as any)).filter;
  }, [searchParams]);

  const getCurrentSort = useCallback(():
    | Record<string, "asc" | "desc" | Record<string, "asc" | "desc">>
    | undefined => {
    return decodeURLToFilter(new URLSearchParams(searchParams as any)).sort;
  }, [searchParams]);

  const updateFilters = useCallback(
    (newFilter: VehicleFilter, shouldScroll = false) => {
      startTransition(() => {
        setQueryOptions((prev) => ({
          ...prev,
          filter: newFilter,
          options: {
            ...prev.options,
            page: 1, // Reset page when filters change
          },
        }));

        const currentParams = encodeFilterToURL(newFilter, queryOptions.options?.sort);
        router.replace(`${pathname}?${currentParams.toString()}`, {
          scroll: shouldScroll,
        });
      });
      return { filter: newFilter };
    },
    [pathname, queryOptions.options, router],
  );

  const updateOptions = useCallback(
    (newOptions: VehicleOptions, shouldScroll = false) => {
      const filter = encodeFilterToURL(queryOptions.filter ?? {}, newOptions?.sort);

      startTransition(() => {
        setQueryOptions((prev) => ({
          ...prev,
          options: {
            ...prev.options,
            ...newOptions,
          },
        }));

        router.replace(`${pathname}?${filter.toString()}`, {
          scroll: shouldScroll,
        });
      });
      return { options: newOptions };
    },
    [queryOptions.filter, router, pathname],
  );

  const updateQuery = useCallback(
    (newQuery: Partial<OmittedVehicleGetDTO>) => {
      const filter = encodeFilterToURL(queryOptions.filter ?? {}, newQuery?.options?.sort);

      startTransition(() => {
        setQueryOptions((prev) => ({
          ...prev,
          ...newQuery,
          options: {
            ...prev.options,
            ...newQuery.options,
            validate: () => [],
          },
        }));

        if (newQuery.filter) {
          updateFilters(newQuery.filter as VehicleFilter, false);
        }

        router.replace(`${pathname}?${filter.toString()}`, {
          scroll: false,
        });
      });
    },
    [pathname, queryOptions.filter, router, updateFilters],
  );

  const resetQuery = useCallback(() => {
    const newState = {
      filter: new VehicleFilter(),
      options: { sort: undefined, page: 1 },
    };

    startTransition(() => {
      setQueryOptions((_) => newState);
      router.replace(pathname, { scroll: true });
    });
  }, [pathname, router, setQueryOptions]);

  const contextValue = useMemo(
    () => ({
      queryOptions,
      updateQuery,
      resetQuery,
      initialQuery: baseQuery,
      getCurrentFilters,
      getCurrentSort,
      updateFilters,
      updateOptions,
      encodeFilterToURL,
      isStateUpdating: isPending,
    }),
    [queryOptions, updateQuery, resetQuery, getCurrentFilters, updateFilters, updateOptions, getCurrentSort, isPending],
  );

  return <FilterContext.Provider value={contextValue}>{children}</FilterContext.Provider>;
};

export const useFilterContext = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error("useFilterContext must be used within a FilterProvider");
  }
  return context;
};

export default FilterContext;
