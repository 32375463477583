import { makeArray, VehicleModelGetDTO } from "@alba-cars/common-modules";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";

import { fuelTypeOptions } from "../_components/_constants";
import { useFilterContext } from "../_components/context/FilterContext";
import { getVehicleBodyTypesOptions } from "@/lib/api/get-body-types";
import { getVehicleMakesOptions } from "@/lib/api/get-makes";
import { getVehicleModelsOptions } from "@/lib/api/get-models";
import { getVehicleFeaturesOptions } from "@/lib/api/get-vehicle-features";

const CACHE_TIME = 30 * 60 * 1000; // 30 minutes
const STALE_TIME = 10 * 60 * 1000; // 10 minutes

const createQueries = (makes: string | string[] | undefined) => [
  {
    queryKey: ["car-makes"],
    // * Pass "relevant" to only get the makes that have cars in stock
    queryFn: () =>
      getVehicleMakesOptions({ relevant: "1", options: { limit: 1000, validate: () => [] }, validate: () => [] }),
    staleTime: STALE_TIME,
    cacheTime: CACHE_TIME,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
  },
  {
    queryKey: ["car-models", { makes: makeArray(makes) }],
    queryFn: () => {
      const dto = new VehicleModelGetDTO() as VehicleModelGetDTO & { relevant?: string };
      dto.filter = {
        makes,
        validate(): string[] {
          return []; // Implement proper validation if needed
        },
      };
      dto.options = {
        limit: 1000,
        validate: () => [],
      };

      // * This is to get only the models that have cars in stock
      dto.relevant = "1";

      return getVehicleModelsOptions(dto);
    },
    staleTime: STALE_TIME,
    cacheTime: CACHE_TIME,
    enabled: !!makes,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
  },
  {
    queryKey: ["car-features"],
    queryFn: () =>
      getVehicleFeaturesOptions({
        options: { limit: 1000, validate: () => [] },
        validate: () => [],
      }),
    staleTime: Infinity, // Features rarely change
    cacheTime: CACHE_TIME,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
  },
  {
    queryKey: ["car-body-types"],
    // * Pass "relevant" to only get the body types that have cars in stock
    queryFn: () =>
      getVehicleBodyTypesOptions({
        relevant: "1",
        options: { limit: 1000, validate: () => [] },
        validate: () => [],
      }),
    staleTime: Infinity, // Body types rarely change
    cacheTime: CACHE_TIME,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
  },
];

export const useVehicleFiltersData = () => {
  const queryClient = useQueryClient();
  const { queryOptions } = useFilterContext();

  // Initialize make from filter, but don't watch for changes
  const [makes, setMakes] = useState<string | string[] | undefined>(() => queryOptions?.filter?.make ?? undefined);

  const queriesConfig = useMemo(() => createQueries(makes), [makes]);

  const queries = useQueries({
    queries: queriesConfig,
  });

  const [
    { data: makesOptions = [], isLoading: isMakesOptionsLoading },
    { data: modelsOptions = [], isLoading: isModelsOptionsLoading },
    { data: featuresOptions = [], isLoading: isFeaturesOptionsLoading },
    { data: bodyTypeOptions = [], isLoading: isBodyTypeOptionsLoading },
  ] = queries;

  const sortOptions = [
    { label: "Newest Models", value: { year: "desc" } },
    { label: "Recently Added", value: { createdAt: "desc" } },
    // { label: "Oldest First", value: { year: "asc" } },
    { label: "High to Low Price", value: { finance: { price: "desc" } } },
    { label: "Low to High Price", value: { finance: { price: "asc" } } },
    { label: "Lowest Mileage", value: { mileage: "asc" } },
  ] as const;

  const makesLookup = useMemo(() => new Map(makesOptions?.map((make) => [make.value, make]) ?? []), [makesOptions]);

  const modelsLookup = useMemo(
    () => new Map(modelsOptions?.map((model) => [model.value, model]) ?? []),
    [modelsOptions],
  );

  const bodyTypeLookup = useMemo(
    () => new Map(bodyTypeOptions?.map((bodyType) => [bodyType.value, bodyType]) ?? []),
    [bodyTypeOptions],
  );

  const fuelTypeLookup = useMemo(() => new Map(fuelTypeOptions.map((fuelType) => [fuelType.value, fuelType])), []);

  const featuresLookup = useMemo(
    () => new Map(featuresOptions?.map((option) => [option.value, option]) ?? []),
    [featuresOptions],
  );

  const handleSetMakes = useCallback(
    (newMakes: string | string[] | undefined) => {
      setMakes(newMakes);
      // Clear models cache when make changes
      if (!newMakes) {
        queryClient.removeQueries({ queryKey: ["car-models"] });
      }
    },
    [queryClient],
  );

  return {
    sortOptions,
    makesOptions,
    modelsOptions,
    featuresOptions,
    bodyTypeOptions,
    isMakesOptionsLoading,
    isModelsOptionsLoading,
    isFeaturesOptionsLoading,
    isBodyTypeOptionsLoading,
    makesLookup,
    modelsLookup,
    bodyTypeLookup,
    featuresLookup,
    fuelTypeLookup,
    setMakes: handleSetMakes,
  } as const;
};
