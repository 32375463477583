"use client";

import { priceOptions } from "./_constants";
import RangeFilter from "../../../components/ui/RangeFilter";
import { FC, useEffect } from "react";
import { useFilterContext } from "./context/FilterContext";

interface PriceFilterProps {
  onFromPriceChange: (value: { value: number; label: string } | null) => void;
  onToPriceChange: (value: { value: number; label: string } | null) => void;
  onFromPriceClear: () => void;
  onToPriceClear: () => void;
  onReset: () => void;
  fromValue?: { value: number; label: string } | null;
  toValue?: { value: number; label: string } | null;
}

const PriceFilter: FC<PriceFilterProps> = ({
  onFromPriceChange,
  onToPriceChange,
  onFromPriceClear,
  onToPriceClear,
  onReset,
  fromValue,
  toValue,
}) => {

  return (
    <RangeFilter
      onFromChange={onFromPriceChange}
      onToChange={onToPriceChange}
      options={priceOptions}
      label="Price"
      onFromClear={onFromPriceClear}
      onToClear={onToPriceClear}
      onReset={onReset}
      fromValue={fromValue}
      toValue={toValue}
    />
  );
};

export default PriceFilter;
